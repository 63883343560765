<template>
	<b-modal centered hide-footer v-model="show" @close="close" @hide="close">
		<template slot="modal-title" v-if="dateInfo">
			<div class="d-flex">
				<div class="circle flex-1 mr-50" :style="{ background: dateInfo.stage.color }"></div>
				<h5 class="mb-0 font-weight-bold">{{ title }}</h5>
			</div>
		</template>
		<div v-if="dateInfo">
			<div>
				<div class="d-flex align-items-center">
					<FeatherIcon icon="TargetIcon" size="16" />
					<h4 class="mb-0 ml-50">Torneo</h4>
				</div>
				<p class="mb-0 ml-2">{{ dateInfo ? dateInfo.tournament.name : "" }}</p>
			</div>

			<div class="line"></div>

			<div>
				<div class="d-flex align-items-center">
					<FeatherIcon icon="TargetIcon" size="16" />
					<h4 class="mb-0 ml-50">Coliseo</h4>
				</div>
				<p class="mb-0 ml-2">{{ dateInfo ? `${dateInfo.coliseum.name} / ${dateInfo.coliseum.city}` : "" }}</p>
			</div>

			<div class="line"></div>

			<div class="grid-method-type">
				<div>
					<div class="d-flex align-items-center">
						<FeatherIcon icon="TargetIcon" size="16" />
						<h4 class="mb-0 ml-50">Metodo</h4>
					</div>
					<p class="mb-0 ml-2">
						{{ dateInfo ? dateInfo.method : "" }}
						{{ dateInfo.method_id == 2 ? `/ ${dateInfo.weight}Kg` : "" }}
					</p>
				</div>
				<div>
					<div class="d-flex align-items-center">
						<FeatherIcon icon="TargetIcon" size="16" />
						<h4 class="mb-0 ml-50">Tipo</h4>
					</div>
					<p class="mb-0 ml-2">{{ dateInfo ? dateInfo.name : "" }}</p>
				</div>
			</div>

			<div class="line"></div>

			<div>
				<div class="d-flex align-items-center">
					<FeatherIcon icon="TargetIcon" size="16" />
					<h4 class="mb-0 ml-50">Frentes</h4>
				</div>
				<div class="grid-specimens">
					<div>
						<p class="mb-0 ml-2">
							Pendientes:
							<span>{{ dateInfo ? `${dateInfo.count_fronts_active}` : "" }}</span>
						</p>
					</div>
					<div>
						<p class="mb-0 ml-2">
							Finalizados:
							<span>{{ dateInfo ? `${dateInfo.count_fronts_finished}` : "" }}</span>
						</p>
					</div>
				</div>
				<div class="grid-specimens">
					<div>
						<p class="mb-0 ml-2">
							Por Frente:
							<span>{{ dateInfo ? ` S./${fixedMoney(dateInfo.amount_single)}` : "" }}</span>
						</p>
					</div>
					<div>
						<p class="mb-0 ml-2">
							Total:

							<span>
								{{ dateInfo ? `	${dateInfo.currency.name} ${fixedMoney(dateInfo.amount_total)}` : "" }}
							</span>
						</p>
					</div>
				</div>
			</div>

			<div class="line"></div>

			<div>
				<div class="d-flex align-items-center">
					<FeatherIcon icon="TargetIcon" size="16" />
					<h4 class="mb-0 ml-50">Ejemplares</h4>
				</div>
				<div class="grid-specimens">
					<div class="d-flex align-items-center">
						<p class="mb-0 ml-2">Requeridos:</p>
						<p class="mb-0 ml-25">{{ dateInfo ? `${dateInfo.specimens_required}` : "0" }}</p>
					</div>
					<div class="d-flex">
						<p class="mb-0 ml-2">Registrados:</p>
						<p
							@click="
								dateInfo.specimens_registered > 0 &&
									$emit('selectSpecimensByCalendarDate', {
										id: dateInfo.tournament.id,
										event_id: dateInfo.id,
										name: `${dateInfo.tournament.name} / ${dateFormat(dateInfo.date)}`,
									})
							"
							class="cursor-pointer mb-0 ml-25"
							:class="[dateInfo.specimens_registered > 0 ? 'font-weight-bolder' : '']"
						>
							{{ dateInfo.specimens_registered || "0" }}
						</p>
					</div>
				</div>
			</div>

			<div class="line"></div>
		</div>
	</b-modal>
</template>

<script>
import tournamentService from "@/services/tournament/default.service"
import moment from "moment"

export default {
	name: "DateDetailsByCalendar",
	props: {
		showModal: {
			type: Boolean,
			default: false,
		},
		info: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			show: this.showModal,
			dateInfo: null,
		}
	},
	computed: {
		title() {
			return this.dateInfo
				? `${this.dateInfo.stage.name}- ${moment(this.dateInfo.date).format("MM/DD/YYYY")}`
				: "Sin titulo"
		},
	},
	methods: {
		close() {
			this.$emit("close")
			this.show = false
		},
		dateFormat(date) {
			return moment(date).format("MM/DD/YYYY")
		},
	},
	watch: {
		async showModal() {
			this.show = this.showModal
			if (this.showModal) {
				this.isPreloading()
				const { data } = await tournamentService.getDetailsDateByCalendar({ date_id: this.info.date_id })
				this.dateInfo = data
				this.isPreloading(false)
			}
		},
	},
}
</script>

<style scoped>
.circle {
	height: 16px !important;
	width: 16px !important;
	background: #f5f5f5;
	border-radius: 50%;
}
h5 {
	max-width: 400px !important;
}
.grid-method-type {
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.grid-specimens {
	display: grid;
	grid-template-columns: 1fr 1fr;
}
.line {
	height: 2px;
	width: 100%;
	background: var(--dark);
	margin: 1rem 0;
}
h4 {
	color: var(--primary);
	font-weight: bold;
}
</style>
