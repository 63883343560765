<template>
	<div>
		<div class="  bg-white container-leyend">
			<div class="mt-2 mb-2 mr-2">
				<div class="d-flex justify-content-start">
					<h5 class="mb-0 d-flex justify-content-start align-items-center text-left">Leyenda</h5>
				</div>
				<div class="d-flex">
					<div class="d-flex align-items-center justify-content-start mt-50">
						<v-icon
							name="fa-trophy"
							scale="1.2"
							class="cursor-pointer ml-25"
							:style="{ color: colorizePosition(1) }"
						/>
						<small class="mb-0 ml-25">Clasificación</small>
					</div>

					<div class="d-flex align-items-center justify-content-start mt-50">
						<v-icon
							name="fa-trophy"
							scale="1.2"
							class="cursor-pointer ml-25"
							:style="{ color: colorizePosition(2) }"
						/>
						<small class="mb-0 ml-25">Semifinal</small>
					</div>

					<div class="d-flex align-items-center justify-content-start mt-50">
						<v-icon
							name="fa-trophy"
							scale="1.2"
							class="cursor-pointer ml-25"
							:style="{ color: colorizePosition(3) }"
						/>
						<small class="mb-0 ml-25">Final</small>
					</div>

					<div class="d-flex align-items-center justify-content-start mt-50">
						<v-icon
							name="fa-trophy"
							scale="1.2"
							class="cursor-pointer ml-25"
							:style="{ color: colorizePosition(4) }"
						/>
						<small class="mb-0 ml-25">Posible final</small>
					</div>
				</div>
			</div>
		</div>
		<div class="p-2 bg-white" style="margin-bottom: 12rem">
			<div class="mb-4" style="">
				<div class="d-flex flex-column flex-lg-row justify-content-between">
					<div class="d-flex flex-column">
						<b-nav v-if="widthScreen < 900" pills class="m-0 d-flex flex-column flex-md-row container-tabs">
							<b-nav-item
								style="min-width: 14rem; border-radius: 8px"
								class="position-relative h-100 bg-primary"
								active-class="active"
								:to="{ name: 'tournaments-canvan' }"
							>
								<span class="text-white font-weight-bolder">Ver Torneos</span>
							</b-nav-item>
						</b-nav>
						<div v-if="stages.length > 0 && widthScreen < 900" class="mt-1">
							<h4>Etapas de torneos</h4>
							<div v-for="(stage, index) in stages" :key="index" class="d-flex align-items-center">
								<div class="circle" :style="{ background: `${stage.color}` }"></div>
								<p class="mb-0 ml-50">{{ stage.stage_name }}</p>
							</div>
						</div>
					</div>
					<div class="container-tabs-calendar">
						<b-nav v-if="widthScreen > 900" pills class="m-0 d-flex flex-column flex-md-row container-tabs">
							<b-nav-item
								style="min-width: 14rem; border-radius: 8px"
								class="position-relative h-100 bg-primary"
								active-class="active"
								:to="{ name: 'tournaments-canvan' }"
							>
								<span class="text-white font-weight-bolder">Ver Torneos</span>
							</b-nav-item>
						</b-nav>
						<div class="d-flex align-items-center mt-1 mt-md-0">
							<div class="d-flex align-items-center justify-content-between">
								<h2 class="m-0 font-weight-bolder text-center" v-if="monthNow">
									{{ yearNow }}
									{{ monthNow.name }}
								</h2>
							</div>
							<div class="grid-icons-arrow d-flex icons-container ml-50">
								<template v-if="tabCalendar === 1">
									<div class="d-flex">
										<feather-icon
											class="icon-arrow-calendar"
											@click="setDateArrow(-30, dateNow)"
											icon="ChevronLeftIcon"
											size="20"
										/>
										<feather-icon
											class="icon-arrow-calendar"
											@click="setDateArrow(30, dateNow)"
											icon="ChevronRightIcon"
											size="20"
										/>
									</div>
									<b-button variant="outline-primary" @click="getHoy" class="ml-50">
										<span class="text-nowrap ml-2 mr-2">Hoy</span>
									</b-button>
								</template>
							</div>
						</div>
					</div>
				</div>

				<div class="container-calendar-total calendario-lotes">
					<div>
						<template v-if="tabCalendar === 1">
							<div class="container-grid-calendar content-days mt-1">
								<p
									v-for="(day, key) in arrayDays"
									:key="key"
									class="day font-small-2 day-item font-weight-bolder"
									:style="{ color: 'red' }"
								>
									{{ widthScreen < 600 ? day.name.substr(0, 3).toUpperCase() : day.name }}
								</p>
							</div>
							<div
								v-for="(semana, key) in calendar"
								:key="key"
								class="container-grid-calendar grid-semana"
							>
								<DayCalendar
									v-for="(day, key) in semana"
									:key="`up-${key}`"
									:day="day"
									:height="height"
									:open="open"
									@date-selected="setFixedEvents"
									@selectDateByCalendar="selectDateByCalendar"
								/>
							</div>
						</template>
					</div>
				</div>
			</div>
			<FixedEventsMobile :info="fixedEvents" @selectDateByCalendar="selectDateByCalendar" />
			<DateDetailsByCalendar
				@close="
					selectDate.show = false
					selectDate.info = null
				"
				:showModal="selectDate.show"
				:info="selectDate.info"
				@selectSpecimensByCalendarDate="selectSpecimensByCalendarDate"
			/>
			<SpecimensPerTournamentModal
				:show="specimensTournamentModal.show"
				:info="specimensTournamentModal.info"
				@closing="specimensTournamentModal.show = false"
			/>
		</div>
	</div>
</template>

<script>
import { initCalendarData } from "@/helpers/calendar"
import DayCalendar from "@/views/amg/tournaments/v2/components/calendar/DayCalendar.vue"
import tournamentService from "@/services/tournament/default.service"
import DateDetailsByCalendar from "../components/calendar/DateDetailsByCalendar.vue"
import SpecimensPerTournamentModal from "../components/SpecimensPerTournamentModal.vue"
import FixedEventsMobile from "../components/calendar/FixedEventsMobile.vue"
import moment from "moment"

export default {
	name: "Calendar",
	components: { DayCalendar, DateDetailsByCalendar, SpecimensPerTournamentModal, FixedEventsMobile },
	data() {
		return {
			arrayDays: [
				{
					name: "Domingo",
					id: 0,
				},
				{
					name: "Lunes",
					id: 1,
				},
				{
					name: "Martes",
					id: 2,
				},
				{
					name: "Miercoles",
					id: 3,
				},
				{
					name: "Jueves",
					id: 4,
				},
				{
					name: "Viernes",
					id: 5,
				},
				{
					name: "Sabado",
					id: 6,
				},
			],
			tabCalendar: 1,
			calendar: [],
			height: this.widthScreen < 600 ? 50 : 170,
			open: this.widthScreen >= 600,
			selectDate: {
				show: false,
				info: null,
			},
			dateNow: new Date(),
			monthNow: null,
			yearNow: null,
			stages: [],
			specimensTournamentModal: {
				show: false,
				info: {},
			},
			fixedEvents: null,
		}
	},
	async mounted() {
		await this.init()
	},
	computed: {
		widthScreen() {
			return this.$store.state.app.windowWidth
		},
	},
	methods: {
		async init() {
			this.isPreloading()
			const { calendar, dateFn, dateIn, monthNow, yearNow } = initCalendarData(this.dateNow)
			this.calendar = calendar
			this.yearNow = yearNow
			this.monthNow = monthNow
			const { data: dates } = await tournamentService.getCalendarTournament({ dateIn, dateFn })
			for (let index = 0; index < calendar.length; index++) {
				const week = calendar[index]
				for (let index = 0; index < week.length; index++) {
					const elementWeek = week[index]
					const existDates = dates.some((it) => it.date == elementWeek.date)
					if (existDates) elementWeek.data = dates.find((it) => it.date == elementWeek.date).dates
				}
			}
			// Conseguir array con stages
			this.reloadStagesCalendar(dates)

			// obtener dia actual para fixedEvents
			let todayStr = moment().format("YYYY-MM-DD")
			for (let i = 0; i < calendar.length; i++) {
				const week = calendar[i]
				if (this.fixedEvents) break

				for (let j = 0; j < week.length; j++) {
					const day = week[j]
					if (this.fixedEvents) break
					if (day.date == todayStr) this.fixedEvents = day
				}
			}

			this.isPreloading(false)
		},

		colorizePosition(position) {
			switch (position) {
				case 1:
					return "#ff912a"
				case 2:
					return "#00E4D0"
				case 3:
					return "#2F1F62"
				default:
					return "#70ff8b"
			}
		},

		selectDateByCalendar(date_id) {
			if (!date_id) {
				this.showToast("info", "top-right", "Calendario", "WarningIcon", "Este día es una posible final.")
				return
			}
			this.selectDate.show = true
			this.selectDate.info = { date_id }
		},
		selectSpecimensByCalendarDate(data) {
			this.specimensTournamentModal.info = { ...data }
			this.specimensTournamentModal.show = !this.specimensTournamentModal.show
		},
		async setDateArrow(daysNumber, day) {
			this.dateNow = new Date(moment(day).add(daysNumber, "days").format("YYYY-MM-DD"))
			await this.init()
		},
		reloadStagesCalendar(dates) {
			let data = []
			for (let index = 0; index < dates.length; index++) {
				const element = dates[index]
				data = [
					...data,
					...element.dates.map((it) => ({
						stage_name: it.stage_name,
						stage_id: it.stage_id,
						color: it.color,
					})),
				]
			}
			const uniqueData = []
			for (let index = 0; index < data.length; index++) {
				const element = data[index]
				if (!uniqueData.map((it) => it.stage_id).includes(element.stage_id)) {
					uniqueData.push(element)
				}
			}
			this.stages = uniqueData
		},
		async getHoy() {
			this.dateNow = new Date()
			await this.init()
		},
		setFixedEvents(day) {
			this.fixedEvents = day
		},
	},
}
</script>

<style lang="scss" scoped>
.container-leyend {
	display: flex;
	flex-direction: column;
	align-items: end;
}
.container-grid-calendar {
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	//margin: 2rem 0 0 0;
}
.container-by-week {
	grid-template-columns: 1fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr;
}
.container-by-day {
	grid-template-columns: 1fr 8fr;
}
.grid-semana {
	//margin-top: 0;
}
.dark-layout {
	.content-days {
		p {
			color: #f4f4f4 !important;
		}
	}
}
.content-days {
	transition: 0.2s all ease-in-out;
	margin-bottom: 0;
	border-bottom: 1px solid #e5e5e5;
	p {
		color: #656565 !important;
	}
}
.dark-layout .content-days {
	border-color: #2d3446;
}
.day-item {
	height: 30px !important;
	@media screen and (min-width: 600px) {
		font-size: 16px !important;
	}
}
.grid-calendar-tasks {
	background: #fff;
	border-radius: 10px;
	padding: 1rem;
	@media screen and (min-width: 600px) {
		padding: 1.6rem;
	}
	.item-task {
		margin-bottom: 0.8rem;
		.title {
			color: #2c2c2c;
			margin: 0;
			font-weight: 500;
		}
		.date {
			display: flex;
			align-items: center;

			p {
				margin: 0;
			}
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
}
.circle {
	height: 12px !important;
	width: 12px !important;
	background: #d5d5d5;
	border-radius: 50%;
	margin-right: 0;
}
.dark-layout .grid-calendar-tasks {
	.item-task {
		.title {
			color: #fff;
		}
	}
}
.container-tabs-calendar {
	display: flex;

	flex-direction: column;
	align-items: flex-end;

	@media screen and (min-width: 900px) {
		width: 100%;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}
	h2 {
		// width: 210px;
		color: #5c5c5c;
	}
	.icons-container {
		// margin-top: 0.5rem;
		@media screen and (min-width: 900px) {
			margin-top: 0;
		}
	}
	.container-tabs {
		@media screen and (min-width: 900px) {
			order: 2;
		}
	}
}
.calendario-lotes {
	grid-template-columns: 1fr !important;
	margin: 0.5rem;
}
.icon-calendar-smart {
	color: #181818;
	transition: 0.3s all ease-in-out;
}
.dark-layout .icon-calendar-smart {
	color: #fff;
}
.icons-select {
	color: #3594f0 !important;
}
.item-tab-day {
	position: relative;
	cursor: pointer;
	.day-number {
		position: absolute;
		top: -4%;
		left: 50%;
		transform: translateX(-50%);
		font-size: 13px !important;
	}
}

.grid-icons-arrow {
	// margin-top: 0.5rem;
	// width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	@media screen and (min-width: 600px) {
		justify-content: flex-start;
	}
	.icon-arrow-calendar {
		cursor: pointer;
		background: #7367f0;
		color: #fff;
		margin: 0 0.2rem;
		border-radius: 0.2rem;
		padding: 0.2rem;
	}
}

.list-enter-active,
.list-leave-active {
	transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
	opacity: 0;
	transform: translateX(30px);
}
.container-select-type-calendar {
	border: 1px solid #b3b3b3;
	border-radius: 6px;
	padding: 0.3rem 1.2rem;
	cursor: pointer;
	margin-right: 0.5rem;
	transition: 0.3s all ease-in-out;
	box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
	&:hover {
		background: #f0f0f0;
	}
	@media screen and (min-width: 600px) {
		margin-right: 1rem;
	}
}
.dark-layout .container-select-type-calendar {
	&:hover {
		background: #454a5a;
	}
}
.calendar-tab-icon {
	margin-left: 8px;
	@media screen and (min-width: 600px) {
		margin-left: 14px;
	}
}
.container-calendar-total {
	.list-total {
		display: none;
	}
	@media screen and (min-width: 2400px) {
		display: grid;
		grid-template-columns: 8fr 2fr;
		gap: 4rem;
		.list-total {
			display: flex;
			.container-calendar-list {
				@media screen and (min-width: 900px) {
					grid-template-columns: 3fr 9fr;
				}
			}
		}
	}
}
.container-calendar-view {
	display: grid;
	grid-template-columns: 1fr;
	gap: 2rem;
	@media screen and (min-width: 900px) {
		// gap: 2rem;
		grid-template-columns: 1fr 5fr;
	}
}
.container-name-stage {
	display: flex;
	align-items: center;
	margin-bottom: 0.5rem;

	.circle-stages {
		margin-bottom: 0;
		// width: 24px !important;
		height: 24px !important;
		min-width: 24px !important;
		background: #7367f0;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		border-radius: 50%;
		font-weight: bolder;
	}
	span {
		margin-left: 0.5rem;
	}
}
.day {
	z-index: 10;
}
</style>
